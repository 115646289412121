import React from 'react';
import '../css/Links.css';
import List from "../assets/Prijslijst_oktober_2024.pdf";

class Links extends React.Component{
    render(){
        return(
            <div className='links__wrapper'>
                <ul className='links'>
                    <a href='https://www.facebook.com/WijnenNathalieKint' target='_blank' rel='noreferrer' className='link__fb'>Facebook</a>
                    <a href='https://www.instagram.com/wijnennathaliekint/' target='_blank' rel='noreferrer' className='link__insta'>Instagram</a>
                    <a href='https://be.linkedin.com/in/nathalie-kint-18461267' target='_blank' rel='noreferrer' className='link__linkedIn'>LinkedIn</a>
                    <a href={List} target='_blank' rel='noreferrer' className='link__list'>Prijslijst</a>
                </ul>
            </div>
        );
    }
}

export default Links;
